import React from 'react';

const VideoArea = () => {
    return (
        <section className="video-area vdeo-bg">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="video-icon-area">
                            <a href="https://youtu.be/OKPd4VE4CZ0" className="video-icon video-popup"><i className="fa fa-play"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="video-content">
                <span><img src="assets/images/video-text.png" alt="" /></span>
                <h1>Watch Our Latest Videos</h1>
                <p>Watch the video above to understand our core values, mission and visions. We explained in details, how we can specifically meet your business need.</p>
                <a href="#" className="video-read-more">Learn More <i className="fa fa-arrow-right"></i></a>
            </div>
        </section>
    );
}

export default VideoArea;
