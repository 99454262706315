import React from 'react';

const ScrollToTop = () => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <div className="scroll-to-top" onClick={scrollToTop}>
            <span id="return-to-top"><i className="fa fa-arrow-up"></i></span>
        </div>
    );
};

export default ScrollToTop;
