import React from 'react';

const CtaArea = () => {
    return (
        <section className="cta-area cta-bg pt-140 pb-140">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="cta-title">
                            <span><img src="assets/images/cta-text.png" alt="" /></span>
                            <h1>You own a school?</h1>
                            <p>We are building a better
                                education system in Nigeria and Africa as a whole, We
                                am more than happy to welcome you on board.
                                Looking forward to working with you</p>
                            <a href="#" className="btn cta-btn">Book a session with us Now<i className="fa fa-arrow-right"></i></a><br />
                            {/* <p className="btn cta-btn">Check out the demo<a href="http://smartedu.eazidev.com.ng" className="">here<i className="fa fa-arrow-right"></i></a></p> */}

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CtaArea;
