import React from 'react';

const ScrollToDown = () => {
    const scrollToDown = () => {
        window.scrollTo({
            bottom: 0,
            behavior: 'smooth'
        });
    };

    return (
        <div className="scroll-to-top" onClick={scrollToDown}>
            <span id="return-to-top"><i className="fa fa-arrow-down"></i></span>
        </div>
    );
};

export default ScrollToDown;
