import React from 'react';

const ServiceOffer = () => {
    return (
        <section className="service-area service-categories pt-100 pb-60">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="single-service-area">
                            <span className="service-num">01</span>
                        </div>
                        <div className="service-icon">
                            <img src="assets/images/service-icon/1.png" alt="" />
                        </div>
                        <div className="service-content">
                            <h4>Modern LMS</h4>
                            <p>Optimize your digital infrastructure for streamlined operations and enhanced performance.<br />
                                Unlock powerful software solutions tailored to your business needs for seamless operations and growth.</p>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="single-service-area">
                            <span className="service-num">02</span>
                        </div>
                        <div className="service-icon">
                            <img src="assets/images/service-icon/2.png" alt="" />
                        </div>
                        <div className="service-content">
                            <h4>State of the Art Financial Management</h4>
                            <p>Experience cutting-edge financial management with our state-of-the-art solutions. Our platform leverages Artificial Intelligence, providing you with a strategic edge over competitors. Maximize your return on investment (ROI) and navigate your business venture with confidence, knowing that our advanced technologies are optimizing your financial processes for unparalleled efficiency and success.</p>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="single-service-area">
                            <span className="service-num">03</span>
                        </div>
                        <div className="service-icon">
                            <img src="assets/images/service-icon/3.png" alt="" />
                        </div>
                        <div className="service-content">
                            <h4>Modern Reporting system for all users</h4>
                            <p>Elevate your reporting capabilities with our modern reporting system designed for all users. Whether you're an administrator, educator, or student, our platform ensures that everyone has access to a sophisticated reporting interface. Seamlessly track and analyze relevant data, empowering users at every level to make informed decisions. Stay ahead in the data-driven landscape with our user-friendly reporting tools, making insights accessible to everyone in your educational ecosystem.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServiceOffer;
