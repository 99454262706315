import React from 'react';

const AboutUsArea = () => {
    return (
        <section className="about-us-area" id="About">
            <div className="container">
                <div className="row">
                    <div className="about-us-img">
                        <img src="assets/images/about-us/1.png" alt="" className="about-img-1" />
                        <img src="assets/images/about-us/2.png" alt="" className="about-img-2" />
                    </div>
                    <div className="col-lg-5 offset-lg-7">
                        <div className="about-us-content">
                            <div className="section-title about-us-title">
                                <p className="section-para"><span></span>About Us</p>
                                <h1>Your Most Trusted Partner
                                    For Educational Transformation</h1>
                            </div>
                            <div className="about-us-text">
                                <span>E</span>
                                <p>SMARTEDU is a modern and complete school automation software that suits
                                    every school or educational institution.<br />
                                    The system includes 30+ modules with 8 inbuilt user panels: Super Admin,
                                    Admin, Accountant, Teacher, Receptionist, Librarian, Parent and Student.</p>
                            </div>
                            <ul className="about-menu">
                                <li><a href="#"><i className="fas fa-check"></i>Super Admin</a></li>
                                <li><a href="#"><i className="fas fa-check"></i>Admin</a></li>
                                <li><a href="#"><i className="fas fa-check"></i>Accountant</a></li>
                                <li><a href="#"><i className="fas fa-check"></i>Teachers</a></li>
                                <li><a href="#"><i className="fas fa-check"></i>Receptionist</a></li>
                                <li><a href="#"><i className="fas fa-check"></i>Librarian</a></li>
                                <li><a href="#"><i className="fas fa-check"></i>Parent</a></li>
                                <li><a href="#"><i className="fas fa-check"></i>Student</a></li>
                            </ul>
                            <a href="#Contact" className="btn about-btn">Contact Us <i className="fa fa-arrow-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AboutUsArea;
