import React from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';


const Header = () => {
    return (
        <header className="header">
            <div className="top-header-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9">
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="top-header-content">
                                        <span><i className="fas fa-phone"></i>+234 (706) 933 3829</span>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="top-header-content">
                                        <span><i className="fas fa-map-marker-alt"></i>Lagos Nigeria</span>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="top-header-content">
                                        <span><i className="far fa-clock"></i>Sun - Friday : 9 am - 08 pm</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 text-right border">
                            <div className="top-header-social-links">
                                <ul>
                                    <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                    <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                    <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <Navbar collapseOnSelect expand="lg" bg="light" variant="light" className="bottom-header-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2">
                            <div className="logo">
                                <a href="index.html"><img src="assets/images/logo.png" alt="Logo" /></a>
                            </div>
                        </div>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse  >
                            <Nav className="mr-auto">
                                <Nav.Link href="#home" className="current">Home</Nav.Link>
                                <Nav.Link href="#About">About</Nav.Link>
                                <Nav.Link href="#Services">Services</Nav.Link>
                                <Nav.Link href="#portfolio">Portfolio</Nav.Link>
                                <Nav.Link href="#team">Team</Nav.Link>
                                <Nav.Link href="#price">Price</Nav.Link>
                                <NavDropdown title="Blog" id="collasible-nav-dropdown">
                                    <NavDropdown.Item href="blog.html">Latest Blog</NavDropdown.Item>
                                    <NavDropdown.Item href="Blog-Details.html">Blog Details</NavDropdown.Item>
                                </NavDropdown>
                                <Nav.Link href="#Contact">Contact</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </div>
                </div>
            </Navbar> */}

      <div className="bottom-header-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-2">
              <div className="logo">
                <a href="index.html"><img src="smartlogo1.jpg" alt="Logo" /></a>
              </div>
            </div>
            <div className="col-lg-10 text-right">
              <div className="mobile-menu-wrap"></div>
              <div className="header-search">
                <span className="search-btn"><i className="fa fa-search"></i></span>
              </div>
              <div className="main-menu">
                <nav>
                  <ul id="mobile-menu">
                    <li className="current"><a href="#home">Home</a></li>
                    <li><a href="#About">About</a></li>
                    <li><a href="#Services">Solutions</a></li>
                    {/* <li><a href="#portfolio">Portfolio</a></li> */}
                    {/* <li><a href="#team">Partners</a></li> */}
                    {/* <li><a href="#price">Pricing</a></li> */}
                    {/* <li><a href="#Blog">Blog</a>
                      <ul className="submenu">
                        <li><a href="blog.html">Latest Blog</a></li>
                        <li><a href="Blog-Details.html">Blog Details</a></li>
                      </ul>
                    </li> */}
                    <li><a href="#Contact">Contact</a></li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div> 
        </header>
    );
};

export default Header;
