import React from 'react';

const ServiceArea = () => {
    return (
        <section className="service-area service-bg pt-140 pb-140" id="Services">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 offset-lg-3 text-center pb-80">
                        <div className="section-title service-title">
                            <span><img src="assets/images/service-text.png" alt="" /></span>
                            <p className="section-para"><span></span>Core Features</p>
                            <h1>Most Creative Agency
                                For Your Project</h1>
                        </div>
                    </div>
                </div>
                <div className="row pb-30">
                    <div className="col-lg-6">
                        <div className="single-service-box">
                            <div className="single-service-icon-box">
                                <p>01</p>
                                <i className="fas fa-cloud-upload-alt"></i>
                            </div>
                            <div className="service-box-content">
                                <h3>Regular Free Updates</h3>
                                <p>Regular updates are available to download for free.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="single-service-box">
                            <div className="single-service-icon-box">
                                <p>02</p>
                                <i className="fas fa-desktop"></i>
                            </div>
                            <div className="service-box-content">
                                <h3>Responsive User Interface</h3>
                                <p>Smart school will over all kinds of devices seamlessly.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row pb-30">
                    <div className="col-lg-6">
                        <div className="single-service-box">
                            <div className="single-service-icon-box">
                                <p>03</p>
                                <i className="fas fa-users"></i>
                            </div>
                            <div className="service-box-content">
                                <h3>Multiuser Account System</h3>
                                <p>Access for admin, teacher, student, and parent.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="single-service-box">
                            <div className="single-service-icon-box">
                                <p>04</p>
                                <i className="fas fa-file-pdf"></i>
                            </div>
                            <div className="service-box-content">
                                <h3>Export Data in PDF</h3>
                                <p>Export report data in PDF.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row pb-30">
                    <div className="col-lg-6">
                        <div className="single-service-box">
                            <div className="single-service-icon-box">
                                <p>11</p>
                                <i className="fas fa-clock"></i>
                            </div>
                            <div className="service-box-content">
                                <h3>Class Routine Schedule</h3>
                                <p>Very easy to create and manage class routine schedules.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="single-service-box">
                            <div className="single-service-icon-box">
                                <p>12</p>
                                <i className="fas fa-file-alt"></i>
                            </div>
                            <div className="service-box-content">
                                <h3>Home Work Document</h3>
                                <p>Attach and download study documents.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row pb-30">
                    <div className="col-lg-6">
                        <div className="single-service-box">
                            <div className="single-service-icon-box">
                                <p>13</p>
                                <i className="fas fa-eye"></i>
                            </div>
                            <div className="service-box-content">
                                <h3>Parent Monitor Child Activity</h3>
                                <p>Parents can monitor all activities of their child.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="single-service-box">
                            <div className="single-service-icon-box">
                                <p>14</p>
                                <i className="fas fa-chart-bar"></i>
                            </div>
                            <div className="service-box-content">
                                <h3>Chart & Graph Analysis in Fees</h3>
                                <p>Chart & graph representation of fees and expenses.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row pb-30">
                    <div className="col-lg-6">
                        <div className="single-service-box">
                            <div className="single-service-icon-box">
                                <p>15</p>
                                <i className="fas fa-clipboard-check"></i>
                            </div>
                            <div className="service-box-content">
                                <h3>Exam Marks Management</h3>
                                <p>Manage exam marks of all students.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="single-service-box">
                            <div className="single-service-icon-box">
                                <p>16</p>
                                <i className="fas fa-user-cog"></i>
                            </div>
                            <div className="service-box-content">
                                <h3>Profile System</h3>
                                <p>Edit profile settings as you wish.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row pb-30">
                    <div className="col-lg-6">
                        <div className="single-service-box">
                            <div className="single-service-icon-box">
                                <p>17</p>
                                <i className="fas fa-bus"></i>
                            </div>
                            <div className="service-box-content">
                                <h3>Transport Management</h3>
                                <p>Manage transport for all routes.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="single-service-box">
                            <div className="single-service-icon-box">
                                <p>18</p>
                                <i className="fas fa-hotel"></i>
                            </div>
                            <div className="service-box-content">
                                <h3>Hostel Management</h3>
                                <p>Manage all hostels and their rooms.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row pb-30">
                    <div className="col-lg-6">
                        <div className="single-service-box">
                            <div className="single-service-icon-box">
                                <p>19</p>
                                <i className="fas fa-book"></i>
                            </div>
                            <div className="service-box-content">
                                <h3>Library Management</h3>
                                <p>Systematic management of all library books.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="single-service-box">
                            <div className="single-service-icon-box">
                                <p>20</p>
                                <i className="fas fa-hand-holding-usd"></i>
                            </div>
                            <div className="service-box-content">
                                <h3>Best Quality at Lowest Price</h3>
                                <p>Best quality product offered at the lowest price.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row pb-30">
                    <div className="col-lg-6">
                        <div className="single-service-box">
                            <div className="single-service-icon-box">
                                <p>21</p>
                                <i className="fas fa-envelope"></i>
                            </div>
                            <div className="service-box-content">
                                <h3>Internal Messaging</h3>
                                <p>Admin can send private messages to teachers, students, and parents.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="single-service-box">
                            <div className="single-service-icon-box">
                                <p>22</p>
                                <i className="fas fa-calendar-check"></i>
                            </div>
                            <div className="service-box-content">
                                <h3>Daily Attendance</h3>
                                <p>Managing daily attendance is now hassle-free.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row pb-30">
                    <div className="col-lg-6">
                        <div className="single-service-box">
                            <div className="single-service-icon-box">
                                <p>19</p>
                                <i className="fas fa-print"></i>
                            </div>
                            <div className="service-box-content">
                                <h3>Print Records</h3>
                                <p>Take printout of every record.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="single-service-box">
                            <div className="single-service-icon-box">
                                <p>20</p>
                                <i className="fas fa-money-bill-wave"></i>
                            </div>
                            <div className="service-box-content">
                                <h3>Accounting</h3>
                                <p>Trace student fees and expenses all at a place.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row pb-30">
                    <div className="col-lg-6">
                        <div className="single-service-box">
                            <div className="single-service-icon-box">
                                <p>23</p>
                                <i className="fas fa-users"></i>
                            </div>
                            <div className="service-box-content">
                                <h3>Sibling Management</h3>
                                <p>Manage multiple children of a single parent in one parent account.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="single-service-box">
                            <div className="single-service-icon-box">
                                <p>24</p>
                                <i className="fas fa-sitemap"></i>
                            </div>
                            <div className="service-box-content">
                                <h3>Class-Section Organization</h3>
                                <p>Organize classes in multiple sections for easier management.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row pb-30">
                    <div className="col-lg-6">
                        <div className="single-service-box">
                            <div className="single-service-icon-box">
                                <p>25</p>
                                <i className="fas fa-database"></i>
                            </div>
                            <div className="service-box-content">
                                <h3>Database Backup / Restore</h3>
                                <p>Easily backup and restore the whole database.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="single-service-box">
                            <div className="single-service-icon-box">
                                <p>26</p>
                                <i className="fas fa-sms"></i>
                            </div>
                            <div className="service-box-content">
                                <h3>SMS Gateway Integration</h3>
                                <p>Get informed about student marks and events with SMS notifications.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row pb-30">
                    <div className="col-lg-6">
                        <div className="single-service-box">
                            <div className="single-service-icon-box">
                                <p>27</p>
                                <i className="fas fa-hand-holding-usd"></i>
                            </div>
                            <div className="service-box-content">
                                <h3>Manual Payment</h3>
                                <p>Ability to take manual payments in cash, cheque, DD.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="single-service-box">
                            <div className="single-service-icon-box">
                                <p>28</p>
                                <i className="fab fa-cc-paypal"></i>
                            </div>
                            <div className="service-box-content">
                                <h3>Online PayPal Payment</h3>
                                <p>Parents can pay student fees from their parent account.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row pb-30">
                    <div className="col-lg-6">
                        <div className="single-service-box">
                            <div className="single-service-icon-box">
                                <p>29</p>
                                <i className="fas fa-paint-brush"></i>
                            </div>
                            <div className="service-box-content">
                                <h3>Aesthetically Designed UI</h3>
                                <p>Design user interface in HTML5, CSS3.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="single-service-box">
                            <div className="single-service-icon-box">
                                <p>30</p>
                                <i className="fas fa-users"></i>
                            </div>
                            <div className="service-box-content">
                                <h3>Active Technical Support</h3>
                                <p>We don't anticipate technical issues, however, we take the smooth running of your school seriously hence the swift response to any issue you peculiar to your school. </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    );
}

export default ServiceArea;
