import React from 'react';

const OffCanvasSearchArea = () => {
    return (
        <div className="offcanvas-search-area">
            <form action="#">
                <input type="text" placeholder="search" />
                <span className="close-bar"><i className="fas fa-times"></i></span>
            </form>
        </div>
    );
};

export default OffCanvasSearchArea;
