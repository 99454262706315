import React from 'react';
import Slider from 'react-slick';
import ScrollToDown from './ScrollToDown';

const Breadcrumb = () => {
    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true, // Enables auto slide show
        autoplaySpeed: 3000
    };

    return (
        <section className="hero-area hero-carousel-active" id="home">
            <Slider {...settings}>
                <div className="single-hero-area hero-bg-1 bg_cover" data-scroll-area="true">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="hero-area-content">
                                    <p className="hero-para">
                                        Revolutionize your school operations with <br />
                                        our modern and complete school automation software
                                    </p>
                                    <h1 className="hero-heading">
                                    School Automation
                                    </h1>
                                    <div className="hero-area-menu">
                                        <ul>
                                            <li><a href="#">Student admission</a></li>
                                            <li><a href="#">Fee collection</a></li>
                                            <li><a href="#">Online Examination</a></li>
                                            <li><a href="#">E-Learning & E-Library management</a></li>
                                        </ul>
                                    </div>
                                    <a href="https://smartedu.eazidev.com.ng/site/login" className="btn hero-btn">Check the Demo <i className="fa fa-arrow-right"></i></a>
                                    <a href="https://youtu.be/OKPd4VE4CZ0" className="video-btn video-popup"><i className="fa fa-play"></i></a>
                                </div>
                            </div>
                        </div>
                        <ScrollToDown />
                    </div>
                </div>
                <div className="single-hero-area hero-bg-2 bg_cover" data-scroll-area="true">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="hero-area-content">
                                    <p className="hero-para">
                                        Elevate your educational institution's efficiency with<br />
                                        our cutting-edge and comprehensive school management solution.
                                    </p>
                                    <h1 className="hero-heading">
                                    School Management Solution
                                    </h1>
                                    <div className="hero-area-menu">
                                        <ul>
                                            <li><a href="#">Student admission</a></li>
                                            <li><a href="#">Fee collection</a></li>
                                            <li><a href="#">Online Examination</a></li>
                                            <li><a href="#">E-Learning & E-Library management</a></li>
                                        </ul>
                                    </div>
                                    <a href="#Contact" className="btn hero-btn">Contact us Now <i className="fa fa-arrow-right"></i></a>
                                    <a href="https://youtu.be/OKPd4VE4CZ0" className="video-btn video-popup"><i className="fa fa-play"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ScrollToDown />
                </div>
                <div className="single-hero-area hero-bg-3 bg_cover" data-scroll-area="true">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="hero-area-content">
                                    <p className="hero-para">
                                        Transform your school's daily operations with<br />
                                        our sophisticated and all-encompassing school management software.
                                    </p>
                                    <h1 className="hero-heading">
                                        All-In-One Solution
                                    </h1>
                                    <div className="hero-area-menu">
                                        <ul>
                                            <li><a href="#">Student admission</a></li>
                                            <li><a href="#">Fee collection</a></li>
                                            <li><a href="#">Online Examination</a></li>
                                            <li><a href="#">E-Learning & E-Library management</a></li>
                                        </ul>
                                    </div>
                                    <a href="#Contact" className="btn hero-btn">get Started <i className="fa fa-arrow-right"></i></a>
                                    <a href="https://youtu.be/OKPd4VE4CZ0" className="video-btn video-popup"><i className="fa fa-play"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ScrollToDown />
                </div>
            </Slider>
        </section>
    );
}

export default Breadcrumb;
